<template>
  <div class="room-header">
    <div class="room-header__content">
      <atom-image
        v-if="community.avatar"
        :src="imageUrl(community.avatar)"
        variant="rounded lg"
        class="room-header__content-img"
      ></atom-image>
      <span class="room-header__content-img-placeholder" v-else>
        {{community.name.slice(0,1)}}
      </span>
      <atom-heading class="room-header__content-name" type="h2" variant="md"
        >{{community.name}}</atom-heading
      >
    </div>
  </div>
</template>

<script setup>
import {toRefs} from "vue";
const props = defineProps({
  community:{
    type:Object,
    required:true
  }
});
const {community} = toRefs(props);
const imageUrl = ((path) => { 
  return path ? process.env.VUE_APP_ASSETS_BASE + path:require('@/assets/images/avatar.jpeg');
});
</script>

<style lang="scss" scoped>
.room-header {
  @apply bg-primary mb-13;
  &__content {
    @apply container mx-auto px-4 flex gap-4 items-center relative top-11;
    &-name {
      @apply mt-4 capitalize;
    }
    &-img{
      &-placeholder{
        @apply w-20 h-20 inline-block bg-primary text-white rounded-full flex justify-center items-center
        text-2xl font-semibold shadow-sm border-white border-[6px] pb-1;
      }
    }
  }
}
</style>
