<template>
  <molecule-card-wrapper class="about-room">
    <atom-heading type="h2" variant="sm" class="about-room__heading"
      >A Propos</atom-heading
    >
    <p class="about-room__description">
      {{community.description}}
    </p>
  </molecule-card-wrapper>
</template>

<script setup>
import MoleculeCardWrapper from "./MoleculeCardWrapper.vue";
import AtomHeading from "@/components/atoms/AtomHeading.vue";
import AtomImage from "@/components/atoms/AtomImage.vue";
import AtomButton from "@/components/atoms/AtomButton.vue";
import MoleculeUserInfos from "@/components/molecules/MoleculeUserInfos.vue";
import {toRefs} from "vue";
const props = defineProps({
  community:{
    type:Object,
    required:true
  }
});
const {community} = toRefs(props);
</script>

<style lang="scss" scoped>
.about-room {
  @apply px-3;
  &__heading {
    @apply mb-4;
  }
  &__subheading {
    @apply mb-3;
  }
  &__description {
    @apply text-sm mb-3;
  }
  &__owner {
    @apply flex items-center gap-1;
    &-infos {
      @apply flex-1;
    }
    &-button {
      @apply border-2 text-base;
    }
  }
}
</style>
