<template>
  <molecule-room-header v-if="community" :community="community"></molecule-room-header>
  <div class="room__container" ref="scrollComponent">
    <main class="room__body">
      <button @click.prevent="router.push({name:'ForumHome'})" class="room__return">
          <atom-icon
            class="room__return-icon"
            icon="arrow-left"
          ></atom-icon>
          <span class="room__return-text">Retour</span>
      </button>
      <molecule-create-post-input
        class="room__body-create-post"
      ></molecule-create-post-input>
      <block-posts :communityName="communityName" :isPrivate="true"/>
    </main>
    <aside class="room__aside">
      <molecule-about-room-card
        class="room__aside-card"
        v-if="community"
        :community="community"
      ></molecule-about-room-card>
      <molecule-news-card class="room__aside-card" v-if="news?.length" :news="news"></molecule-news-card>
    </aside>
  </div>
</template>

<script setup>
import MoleculeCreatePostInput from "@/components/molecules/MoleculeCreatePostInput.vue";
import MoleculeRoomPostCard from "@/components/molecules/cards/MoleculeRoomPostCard.vue";
import MoleculeRoomHeader from "@/components/molecules/MoleculeRoomHeader.vue";
import MoleculeAboutRoomCard from "@/components/molecules/cards/MoleculeAboutRoomCard.vue";
import MoleculeNewsCard from "@/components/molecules/cards/MoleculeNewsCard.vue";
import MoleculeNoData from '@/components/molecules/MoleculeNoData.vue';
import BlockPosts from '@/components/blocks/BlockPosts.vue';
import { ref, computed, onBeforeMount, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute,useRouter } from "vue-router";
import { communitiesMutationTypes, postsMutationTypes } from "@/store/types/mutations";
import { debounce } from "debounce";

const {
  params: { communityName },
} = useRoute();
const router = useRouter();
const { state, getters, dispatch,commit } = useStore();
const community = computed(() =>
  getters["communities/getCommunity"](communityName)
);
const news = computed(() => state.news.news?.data);
const communityPosts = computed(() => getters['posts/getByCommunityName'](communityName));
const scrollComponent = ref(null);
const handleScroll = (e) => {
  let element = scrollComponent.value;
  if (element.getBoundingClientRect().bottom - 50 < window.innerHeight) {
    loadMorePosts();
  }
};
// Change current community name 
commit(`communities/${communitiesMutationTypes.SET_CURRENT_COMMUNITY_NAME}`, communityName);
const fetchData = async () => {
  // Fetch community posts
  if(!communityPosts.value?.length){
    try {
      commit(`posts/${postsMutationTypes.SET_LOADING}`, true);
      await dispatch('posts/getByCommunityName'); 
      commit(`posts/${postsMutationTypes.SET_LOADING}`, false);
    } catch (err) {
      console.log(err);
      // const {message} = err.response.data;
      // console.log(message);
    }
  }
  if(!community.value){
    try {
        commit(`posts/${postsMutationTypes.SET_LOADING}`, true);
        await dispatch('communities/getByName', communityName);
        commit(`posts/${postsMutationTypes.SET_LOADING}`, false);
    } catch (err) {
      console.log(err);
        // const {message} = err.response.data;
        // console.log(message);
    }
  }
}

const loadMorePosts = async () => {
  try {
    await dispatch("posts/getByCommunityName");
  } catch (err) {
    const { message } = err.response.data;
    console.log(message);
  }
};

onBeforeMount( async () => {
  await fetchData();
});

onMounted(() => {
  window.addEventListener("scroll", debounce(handleScroll, 50));
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss" scoped>
.room {
  &__container {
    @apply container mx-auto px-4 mt-2 lg:mt-4
      flex lg:gap-[33px];
  }
  &__body {
    @apply flex-[2] mb-8 relative;  
    &-create-post {
      @apply mb-5;
    }
    &-post {
      @apply mb-3;
    }
  }
  &__aside {
    @apply hidden lg:block flex-1;
    &-card {
      @apply mb-4;
    }
  }
  &__return {
    @apply flex items-center gap-1 text-gray-base text-[20px] font-medium
    absolute right-0 -top-11;
    &-icon {
      @apply h-6 w-6 fill-[gray];
    }
  }
}
</style>